<template>
  <div class="page">
     <van-nav-bar
      class="backBox"
      title="我的账单"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="detail_box">
      <div class="detail_box_header" v-if="detailType === 2">
        <!-- <span class="detail_box_header_num">{{ detailInfo.plate_number }}</span> -->
        <span class="detail_box_header_num">月卡续费</span>
        <span class="detail_box_header_money">-{{ detailInfo.charged_fee / 100 }}</span>
        <span class="detail_box_header_done">交易成功</span>
      </div>
      <div v-if="detailType === 1">
        <div class="detail_item">
          <span class="detail_item_tleft">车场全称</span>
          <span class="detail_item_tright">{{parkName}}</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">停车时长</span>
          <span class="detail_item_tright"
            >{{
              getParkTimeFormatter(detailInfo)
            }}</span
          >
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">入场时间</span>
          <span class="detail_item_tright">{{
            formatDate(detailInfo.in_time)
          }}</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">出场时间</span>
          <span class="detail_item_tright">{{
             formatDate(detailInfo.out_time)
          }}</span>
        </div>
        <div class="detail_item">
          <!-- todo 该接口没有order_type字段 -->
          <span class="detail_item_tleft">付款方式</span>
          <span class="detail_item_tright">{{payTypeFormatter(detailInfo.type)}}</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">支付时间</span>
          <span class="detail_item_tright">{{ formatDate(detailInfo.create_time) }}</span>
        </div>
                <div class="detail_item">
          <span class="detail_item_tleft">进出场照片</span>
          <span class="detail_item_tright viewImg" @click="openInOutImg(detailInfo)">查看详情</span>
        </div>
      </div>

      <!-- 月卡续费 -->
      <div v-if="detailType === 2">
        <!-- <div class="detail_item">
          <span class="detail_item_tleft">付款方式</span>
          <span class="detail_item_tright">{{payTypeFormatter(detailInfo.type)}}</span>
        </div> -->
        <div class="detail_item">
          <span class="detail_item_tleft">支付时间</span>
          <span class="detail_item_tright">{{formatDate(detailInfo.charged_time)}}</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">有效期结束时间</span>
          <span class="detail_item_tright">{{formatDate(detailInfo.effective_date_end)}}</span>
        </div>

      </div>
    </div>
      <!-- 查看照片 -->
      <van-dialog
        v-model="showImg"
        title="进出场照片详情"
        confirm-button-color="#1989fa"
      >
        <div style="text-align: center; margin-top: 3%" v-if="isLoadingImg">
          <van-loading color="#1989fa" text-color="#999" vertical
            >图片加载中，请稍后...</van-loading
          >
        </div>
        <div class="imgBox" v-for="item in inoutImgs" :key="item.id" style="">
          <div class="imgItem">
            <van-image width="260" height="" :src="item.url" fit="cover">
              <template v-slot:default>
                <div style="text-align: center; font-size: 0.12rem">
                  {{ item.text }}
                </div>
              </template>
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
        </div>
      </van-dialog>
    <!-- 照片参考h5 另外接口 -->

      <!-- <div class="img_box" v-if="detailType === 1">
        <div class="img_box_in_out">
          <span class="img_box_tit">入场照片</span>
          <img
            src="../../../assets/img/fapiaojilu2@3x.png"
            class="img_box_img"
          />
        </div>

        <div class="img_box_in_out">
          <span class="img_box_tit">出场照片</span>
          <div class="outImg">
            <img
              src="../../../assets/img/fapiaojilu2@3x.png"
              class="img_box_img"
            />
          </div>
        </div>
      </div> -->

<!-- TODO 联系客服 -->
    <!-- <div class="detail_footer" v-if="detailType === 1">
      <span class="question">对订单有疑问</span>
      <div class="question_tit">
        <span class="question_btn">联系客服</span>
        <img
          src="../../../assets/img/rightjt@2x.png"
          class="question_icon"
        />
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import * as mqtt from '../../../api/mqtt/wsconnect'

import * as common from '../../../api/common'
import * as constant from '../../../api/constant'
import * as util from '../../../api/util'
import * as api from '../../../api/api'
import { mapGetters } from 'vuex'

export default {
  props: ['type', 'item'],
  data () {
    return {
      detailInfo: {},
      detailType: null,
      parkName: '',
      showImg: false,
      inoutImgs: [],
      isLoadingImg: false,
      vh_out_id: '',
      messageTimeout: null
    }
  },
  mounted () {
    this.detailInfo = JSON.parse(decodeURIComponent(this.item))
    this.detailType = Number(this.type)
    const parkName = localStorage.getItem(constant.keyParkNameLocalStorage)
    this.parkName = parkName || '停车场'
  },
  computed: {
    ...mapGetters(['vhColumns', 'openID', 'sid'])
  },
  methods: {
    getParkTimeFormatter (item) {
      return util.countTimeStartEnd(item.in_time, item.create_time)
    },
    // 1 点击查询动作
    async openInOutImg (row) {
      console.log('准备查看图片', row)
      if (this.inoutImgs.length > 0 && this.vh_out_id === row.vh_out_id) {
        this.showImg = true
        return false
      }

      this.inoutImgs = []

      try {
        // 等待订阅操作
        await this.getInoutDetail()
      } catch (e) {
        this.$toast.fail('查询照片出错')
        return
      }

      axios({
        url: common.iotUrl + '/s2p/' + api.urlList.query_in_out_detail,
        method: 'POST',
        data: {
          sid: this.sid,
          in_out_id: row.vh_out_id,
          version: 'v2',
          access_token: 'CA502D23-BB70-0001-55FA-1B501C501A6B',
          app_key: '666a659e-244d-46b1-a8cc-3c5190c3823a'
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then((res) => {
        console.log('请求查看照片返回信息', res)
        if (res.data.code && res.data.code === 10000) {
          this.vh_out_id = row.vh_out_id
          this.showImg = true

          this.visible = false
        } else {
          this.$toast(common.resMSG[res.data.code])
        }
      })
    },
    // 查看出入场照片getInoutDetail
    async getInoutDetail () {
      console.log('getInoutDetail')
      // this.inOrOut = 'OUT';

      var that = this
      // that.$toast.loading({
      //   mask: true,
      //   message: '图片加载中，请稍候...',
      //   duration: 3000,
      // });
      this.isLoadingImg = true

      try {
        await that.init_mqtt_inoutDetail()
        await that.subscribeInOutDetail('sq')
      } catch (e) {
        console.log(e)
        // this.$toast.fail(e)
        throw e
      }
    },
    async init_mqtt_inoutDetail (cb) {
      console.log('init_mqtt_inoutDetail')
      var that = this
      // var utils = util
      const config = {
        onConnected: function () {
          that.isEmqxConnect = true
        },
        // onReconnect: that.subscribe,
        onMessage: this.getInoutDetailResult
      }
      const client = await mqtt.getClient(config)
      if (client && client.connected) {
        // console.log('mqtt已连接', client)
        client.callback.mq_query_inout_detail = this.getInoutDetailResult
        return client
      } else {
        that.$toast.fail('NetWork err!')
        throw new Error('client NetWork err!')
      }
    },
    getInoutDetailResult: async function (topic, message, packet) {
      console.log('接收到mqtt数据', topic, message, packet)
      const that = this

      // Clear any existing timeout
      if (this.messageTimeout) {
        clearTimeout(this.messageTimeout)
      }

      this.isLoadingImg = false
      that.inoutImgs = []

      if (message && message.code === common.RespMsg.OK.code) {
        // If message is valid, process the images
        that.inoutImgs.push({
          id: '0',
          direction: 'in',
          url: message.in_img,
          text: '进场图片'
        })
        that.inoutImgs.push({
          id: '1',
          direction: 'out',
          url: message.img,
          text: '出场图片'
        })
      } else {
        // If message is invalid or has an error code
        return that.$toast(message.msg || '查看照片出错')
      }

      // Set a timeout to handle the case where no message is received
      this.messageTimeout = setTimeout(() => {
        this.isLoadingImg = false
        that.$toast('获取图片失败')
      }, 10000) // Set timeout duration (e.g., 10 seconds)
    },
    // 订阅车辆进出场详情
    subscribeInOutDetail: async function (action, callback) {
      console.log('订阅', action, callback)
      // 订阅
      const topic = `/ypt/u_${this.sid}/mq_query_inout_detail`
      const options = {
        payload: {
          topic: 'mq_query_inout_detail'
        }
      }
      console.log('todo subscribe', topic, options)
      await mqtt.subscribe(topic, options)
    },
    payTypeFormatter (str) {
      return util.getPayType(str)
    },
    monthTypeFormatter (str) {
      const type = Number(str)
      const list = constant.chargeTypeList
      const item = list.find((x) => x.type === type)
      return item ? item.name : '停车缴费'
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    unsubscribe: function (action, callback) {
      // 订阅
      const topic = `/ypt/u_${this.sid}/${encodeURIComponent(
        this.plate_number
      )}`
      const options = {
        payload: {
          topic: action,
          plate_number: this.plate_number
        }
      }
      mqtt.unsubscribe(topic, options, function (err, granted) {
        if (err) {
          callback(err, granted)
          return
        }
        callback(null, granted)
      })
    }

  },

  beforeDestroy () {
    this.unsubscribe('sq', function (err, granted) {
      console.log('退订', err, granted)
      mqtt.close()
    })
    if (this.messageTimeout) {
      clearTimeout(this.messageTimeout)
    }
  }
}
</script>
<style lang="scss" scoped>
.page{
  // background-color: #F8F7FC;
}
.detail_box {
  background-color: rgba(255, 255, 255, 1);
  width: 3.21rem;
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  padding: .08rem .11rem .13rem .11rem;
  .detail_box_header {
    display: flex;
    flex-direction: column;
    margin: 0 1.29rem 0 1.23rem;
    .detail_box_header_num {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .detail_box_header_money {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.18rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.25rem;
      align-self: center;
      margin-top: .02rem;
    }
    .detail_box_header_done {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: .12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.17rem;
      margin: .02rem .1rem 0 .11rem;
    }

    .detail_item_tleft {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }

  .detail_item {
    width: 3.21rem;
    margin-top: 0.16rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .detail_item_tleft {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: .14rem;
    }
    .detail_item_tright {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
}

.img_box {
  width: 3.43rem;
  height: 5.04rem;
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  .img_box_in_out {
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 0.16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .12rem;
    .img_box_tit {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
      /* margin-right: 388rpx; */
    }
    .img_box_img {
      width: 2.5rem;
      height: 1.89rem;
      margin-top: .04rem;
    }
  }
}

.detail_footer {
  background-color: rgba(255, 255, 255, 1);
  width: 3.21rem;
  align-self: center;
  margin-top: .13rem;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: .09rem .11rem .09rem .11rem;
  .question {
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 0.16rem;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.22rem;
  }
  .question_tit {
    width: 0.7rem;
    margin-bottom: .02rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .question_btn {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .question_icon {
      width: .09rem;
      height: .15rem;
      margin: .03rem 0 .02rem 0;
    }
  }
}

.viewImg {
  color: #1989fa !important;
}
.imgBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
}
.imgBox .imgItem {
  /* width: 65%;
  margin-bottom: 3%; */
}

.imgBox img {
  width: 100%;
  height: 100%;
}
</style>
